import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { DialogData } from './models';
import { isAlertDialog, isConfirmDialog } from './utils';

@Component({
  selector: 'app-dialog-generic-component',
  template: `
    <div data-test="generic-dialog">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content
        *ngIf="data.description"
        class="mb-3">
        {{ data.description }}
      </mat-dialog-content>
      <mat-dialog-actions class="flex !justify-end">
        <button
          *ngIf="isAlertDialog(data)"
          mat-flat-button
          mat-dialog-close
          color="accent">
          {{ data.dismissLabel }}
        </button>

        <button
          *ngIf="isConfirmDialog(data)"
          mat-stroked-button
          [mat-dialog-close]="false">
          {{ data.secondaryLabel }}
        </button>
        <button
          *ngIf="isConfirmDialog(data)"
          mat-flat-button
          color="accent"
          data-test="confirmation-dialog-primary-btn"
          [mat-dialog-close]="true">
          {{ data.primaryLabel }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [
  ],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class GenericDialogComponent {
  readonly isConfirmDialog = isConfirmDialog;
  readonly isAlertDialog = isAlertDialog;

  readonly dialog = inject(MatDialog);
  readonly data: DialogData = inject(MAT_DIALOG_DATA);
}
