import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Provider, forwardRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from 'ngx-atred-api-connectors';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GenericDialogComponent } from './confirm-dialog/generic.dialog.component';
import { ApiInterceptor } from './core/interceptors/api-interceptor.service';
import { AuthService } from './core/services';
import { environment } from '../environments/environment';

const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ApiModule.forRoot({
      rootUrl: environment.apiBasePath,
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GenericDialogComponent,
  ],
  providers: [
    API_INTERCEPTOR_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(authService: AuthService) {
    void authService.init();
  }
}
