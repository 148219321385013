import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AdminService } from 'ngx-atred-api-connectors';
import {
  Subject, firstValueFrom, startWith, switchMap,
} from 'rxjs';

import { UpsertGroupDialogComponent } from '../../components';

@Component({
  selector: 'app-groups-page',
  templateUrl: './groups-page.component.html',
  styleUrls: ['./groups-page.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTableModule,
    NgIf,
    RouterModule,
  ],
})
export class GroupsPageComponent {
  private readonly adminApi = inject(AdminService);
  private readonly dialog = inject(MatDialog);

  private readonly listGroupsChanged$ = new Subject();

  readonly groups$ = this.listGroupsChanged$.pipe(
    startWith(undefined),
    switchMap(() => this.adminApi.v0AdminGroupsGet()),
  );

  isLoadingResults = false;
  readonly displayedColumns: string[] = ['name', 'description', 'createdAt', 'show'];

  async createGroupHandler() {
    const ref = this.dialog.open(UpsertGroupDialogComponent, { width: '400px' });
    await firstValueFrom(ref.afterClosed());

    this.listGroupsChanged$.next(undefined);
  }
}
