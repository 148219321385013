<ng-container *ngIf="{ data: user$ | async } as user">
  <div class="flex justify-between">
    <div class="flex flex-row items-center justify-center mb-2">
      <button
        mat-icon-button
        [routerLink]="['/users']">
        <mat-icon
          class="mr-2"
          fontIcon="arrow_back">
        </mat-icon>
      </button>
      <h1 class="!mb-0">User - {{ user.data?.firstName + ' ' + user.data?.lastName }}</h1>
    </div>
  </div>

  <div>
    <div class="mb-2">
      <span class="w-20 inline-block">FirstName:</span>
      <span>{{ user.data?.firstName }}</span>
    </div>
    <div class="mb-2">
      <span class="w-20 inline-block">LastName:</span>
      <span>{{ user.data?.lastName }}</span>
    </div>
    <div class="mb-2">
      <span class="w-20 inline-block">Email:</span>
      <span>{{ user.data?.email }}</span>
    </div>
  </div>

  <div
    *ngIf="user.data"
    class="mt-4">
    <button
      mat-stroked-button
      color="warn"
      (click)="resetPasswordHandler(user.data)">
      Reset password
    </button>
  </div>
</ng-container>
