/* eslint-disable @typescript-eslint/no-unused-vars */

import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);

  canActivate(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot,
  ) {
    return this.authService.accessToken
      ? true
      : this.router.createUrlTree(['/login']);
  }
}
