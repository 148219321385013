import { SelectionModel } from '@angular/cdk/collections';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AdminService, UserItemAdmin } from 'ngx-atred-api-connectors';
import { Subject, firstValueFrom } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { AddUsersGroupDialogComponent } from '../../components/add-users-group-dialog/add-users-group-dialog.component';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTableModule,
    NgFor,
    NgIf,
    RouterModule,
  ],
})
export class UsersPageComponent {
  private readonly adminApi = inject(AdminService);
  private readonly dialog = inject(MatDialog);

  private readonly listUsersChanged$ = new Subject();

  readonly users$ = this.listUsersChanged$.pipe(
    startWith(undefined),
    switchMap(() => this.adminApi.v0AdminUsersGet()),
  );

  readonly usersSelection = new SelectionModel<UserItemAdmin>(true, []);

  isLoadingResults = false;
  readonly displayedColumns: string[] = [
    'select', 'firstName', 'lastName', 'email', 'phoneNumber', 'role', 'state', 'show',
  ];

  async toggleUserStateHandler(user: UserItemAdmin) {
    // FIXME: Prevent to call multiple times this method in order to avoid API calls
    if (!user.id) {
      return;
    }

    if (user.isEnabled) {
      await firstValueFrom(this.adminApi.v0AdminUsersIdDisablePut({ id: user.id }));
    } else {
      await firstValueFrom(this.adminApi.v0AdminUsersIdEnablePut({ id: user.id }));
    }

    this.listUsersChanged$.next(undefined);
  }

  async addGroupHandler() {
    const res = this.dialog.open(
      AddUsersGroupDialogComponent,
      {
        width: '700px',
        data: {
          users: this.usersSelection.selected,
        },
      },
    );

    await firstValueFrom(res.afterClosed());
    this.usersSelection.clear();
  }

  isAllSelected(data: UserItemAdmin[]) {
    const numSelected = this.usersSelection.selected.length;
    const numRows = data.length;
    return numSelected === numRows;
  }

  toggleAllRows(data: UserItemAdmin[]) {
    if (this.isAllSelected(data)) {
      this.usersSelection.clear();
      return;
    }

    this.usersSelection.select(...data);
  }
}
