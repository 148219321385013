import { Component, inject } from '@angular/core';
import {
  FormBuilder, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AdminService, CreateGroupRequest } from 'ngx-atred-api-connectors';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-upsert-group-dialog',
  templateUrl: './upsert-group-dialog.component.html',
  styleUrls: ['./upsert-group-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class UpsertGroupDialogComponent {
  private readonly adminApi = inject(AdminService);
  private readonly dialogRef = inject(MatDialogRef);

  readonly groupForm = inject(FormBuilder).nonNullable.group({
    name: ['', Validators.required],
    description: [''],
  });

  async saveHandler() {
    if (this.groupForm.invalid) {
      this.groupForm.markAllAsTouched();
      return;
    }
    const { name, description } = this.groupForm.getRawValue();
    const group: CreateGroupRequest = { name, description };

    const res = await firstValueFrom(this.adminApi.v0AdminGroupsPost({ body: group }));

    this.dialogRef.close(res);
  }
}
