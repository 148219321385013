<h1 mat-dialog-title>Add users to group</h1>
<form
  mat-dialog-content
  [formGroup]="groupForm"
  (ngSubmit)="saveHandler()">
  <div
    class="!flex !flex-col">
    <mat-form-field>
      <mat-label>Group</mat-label>
      <input
        type="text"
        placeholder="Select a group"
        matInput
        formControlName="group"
        [matAutocomplete]="auto">
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayGroupLabel">
        <mat-option
          *ngFor="let group of groups$ | async"
          [value]="group">
          {{ group.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="flex flex-col">
    <p>Following users will be added to the selected group.</p>

    <div class="flex flex-row">
      <mat-chip-option
        *ngFor="let user of data.users"
        class="mr-2">
        {{ user.email }}
        <button
          matChipRemove
          (click)="removeUser(user)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-option>
    </div>
  </div>

  <div
    mat-dialog-actions
    class="flex !justify-end">
    <button
      class="mr-2"
      mat-stroked-button
      mat-dialog-close>
      Cancel
    </button>
    <button
      type="submit"
      mat-stroked-button
      cdkFocusInitial
      [disabled]="groupForm.invalid || data.users.length === 0">
      Add
    </button>
  </div>
</form>
