<ng-container *ngIf="{ data: group$ | async } as group">
  <div class="flex justify-between">
    <div class="flex flex-row items-center justify-center mb-2">
      <button
        mat-icon-button
        [routerLink]="['/groups']">
        <mat-icon
          class="mr-2"
          fontIcon="arrow_back">
        </mat-icon>
      </button>
      <h1 class="!mb-0">Group - {{ group.data?.name }}</h1>
    </div>

    <button
      mat-flat-button
      color="warn"
      (click)="deleteGroupHandler()">
      <mat-icon fontIcon="delete"></mat-icon>
      Delete group
    </button>
  </div>

  <p *ngIf="group.data?.description">{{ group.data?.description }}</p>


  <h2>Members of the group</h2>

  <div
    *ngIf="{ data: users$ | async } as resolved"
    class="!border border-gray-200">
    <div *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>

    <div>
      <table
        mat-table
        [dataSource]="resolved.data || []">

        <ng-container matColumnDef="firstName">
          <th *matHeaderCellDef mat-header-cell>FirstName</th>
          <td *matCellDef="let row" mat-cell>{{ row.user?.firstName || '-' }}</td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th *matHeaderCellDef mat-header-cell>LastName</th>
          <td *matCellDef="let row" mat-cell>{{ row.user?.lastName || '-' }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th *matHeaderCellDef mat-header-cell>
            Email
          </th>
          <td *matCellDef="let row" mat-cell>{{ row.user?.email || '-' }}</td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th *matHeaderCellDef mat-header-cell>
            Role
          </th>
          <td *matCellDef="let row" mat-cell>
            <mat-select
              [value]="row.role"
              (selectionChange)="changeParticipantRoleHandler(row, $event.value)">
              <mat-option [value]="GroupParticipantRole.Observer">{{ 'Observer' }}</mat-option>
              <mat-option [value]="GroupParticipantRole.Member">{{ 'Member' }}</mat-option>
              <mat-option [value]="GroupParticipantRole.Manager">{{ 'Manager' }}</mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete" stickyEnd>
          <th *matHeaderCellDef mat-header-cell>
          </th>
          <td *matCellDef="let row" mat-cell>
            <button
              mat-icon-button
              (click)="removeUserHandler(row)">
              <mat-icon fontIcon="delete"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr
          *matHeaderRowDef="displayedColumns"
          mat-header-row>
        </tr>
        <tr
          *matRowDef="let row; columns: displayedColumns;"
          mat-row>
        </tr>
      </table>
    </div>

    <mat-paginator
      [length]="resolved.data?.length"
      [pageSize]="250">
    </mat-paginator>
  </div>
</ng-container>
