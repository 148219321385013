<h1 mat-dialog-title>New group</h1>
<form
  [formGroup]="groupForm"
  (ngSubmit)="saveHandler()">
  <div
    mat-dialog-content
    class="!flex !flex-col">
    <mat-form-field>
      <label for="name">Name</label>
      <input
        id="name"
        matInput
        formControlName="name">
    </mat-form-field>
    <mat-form-field>
      <label for="description">Description</label>
      <input
        id="description"
        matInput
        formControlName="description">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close>
      Cancel
    </button>
    <button
      type="submit"
      mat-button
      cdkFocusInitial>
      Create
    </button>
  </div>
</form>
