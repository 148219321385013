import { MediaMatcher } from '@angular/cdk/layout';
import { NgFor } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-inner-root-page',
  templateUrl: './inner-root-page.component.html',
  styleUrls: ['./inner-root-page.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    NgFor,
    RouterModule,
  ],
})
export class InnerRootPageComponent implements OnDestroy {
  private readonly rooter = inject(Router);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly media = inject(MediaMatcher);

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor() {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logoutHandler() {
    return this.rooter.navigate(['/logout']);
  }
}
