import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../core/services';

@Component({
  selector: 'app-logout-page',
  template: '',
  standalone: true,
  imports: [],
})
export class LogoutPageComponent {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);

  constructor() {
    void this.logout();
  }

  private async logout() {
    try {
      await this.authService.logout();
    } finally {
      await this.router.navigate(['/login']);
    }
  }
}
