import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterModule } from '@angular/router';
import { AdminService, GroupParticipantItemAdmin, GroupParticipantRole } from 'ngx-atred-api-connectors';
import {
  BehaviorSubject, Subject, combineLatest, firstValueFrom,
} from 'rxjs';
import {
  filter, map, startWith, switchMap,
} from 'rxjs/operators';

import { GenericDialogService } from '../../confirm-dialog';

@Component({
  selector: 'app-group-page',
  templateUrl: './group-page.component.html',
  styleUrls: ['./group-page.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTableModule,
    NgFor,
    NgIf,
    RouterModule,
  ],
})
export class GroupPageComponent {
  private readonly groupId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() set id(v: string | undefined) {
    this.groupId$.next(v);
  }

  private readonly adminApi = inject(AdminService);
  private readonly router = inject(Router);
  private readonly dialog = inject(GenericDialogService);

  private readonly listUsersChanged$ = new Subject();

  readonly group$ = this.groupId$.pipe(
    filter((groupId): groupId is string => !!groupId),
    switchMap((groupId) => this.adminApi.v0AdminGroupsGet().pipe(
      map((groups) => groups.filter((g) => g.id === groupId)),
    )),
    map((groups) => groups[0]),
  );

  readonly users$ = combineLatest([
    this.groupId$.pipe(filter((groupId): groupId is string => !!groupId)),
    this.listUsersChanged$.pipe(startWith(undefined)),
  ]).pipe(
    switchMap(([groupId]) => this.adminApi.v0AdminGroupsGroupIdParticipantsGet({ id: groupId })),
  );

  isLoadingResults = false;

  readonly displayedColumns: string[] = ['firstName', 'lastName', 'email', 'role', 'delete'];
  readonly GroupParticipantRole = GroupParticipantRole;

  async deleteGroupHandler() {
    const res = await this.dialog.confirm({
      title: 'Remove group',
      description: 'Are you sure to delete this group?',
      primaryLabel: 'Remove',
      secondaryLabel: 'Cancel',
    });

    if (res) {
      await firstValueFrom(this.adminApi.v0AdminGroupsIdDelete({ id: this.groupId$.value || 'Missing id' }));
      await this.router.navigate(['/groups']);
    }
  }

  async removeUserHandler(participant: GroupParticipantItemAdmin) {
    const groupId = this.groupId$.value || 'Missing id';

    await firstValueFrom(this.adminApi.v0AdminGroupsGroupIdParticipantsIdDelete({
      id: participant.id || 'Missing id',
      groupId,
    }));

    this.listUsersChanged$.next(undefined);
  }

  async changeParticipantRoleHandler(participant: GroupParticipantItemAdmin, role: GroupParticipantRole) {
    const groupId = this.groupId$.value || 'Missing id';

    await firstValueFrom(this.adminApi.v0AdminGroupsGroupIdParticipantsIdRolePut({
      id: participant.id || 'Missing id',
      groupId,
      body: { role },
    }));

    this.listUsersChanged$.next(undefined);
  }
}
