<main class="flex h-full w-full items-center justify-center">
  <mat-card class="w-96 p-8">
    <form
      class="flex flex-col items-center"
      [formGroup]="loginForm"
      (ngSubmit)="loginHandler()">
      <h1 class="!mb-8">Neym ATRED Admin</h1>

      <mat-form-field class="w-full">
        <mat-label>Username</mat-label>
        <input
          matInput
          placeholder="Insert username"
          formControlName="username">
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Password</mat-label>
        <input
          matInput
          placeholder="Insert password"
          formControlName="password">
      </mat-form-field>
      <button
        mat-button
        mat-flat-button
        color="primary"
        type="submit">
        Login
      </button>
    </form>
  </mat-card>
</main>
