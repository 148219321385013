<div class="flex justify-between">
  <h1>Users</h1>

  <button
    mat-stroked-button
    [disabled]="usersSelection.selected.length === 0"
    (click)="addGroupHandler()">
    Add to group
  </button>
</div>

<div
  *ngIf="{ data: users$ | async } as resolved"
  class="!border border-gray-200">
  <div *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div>
    <table
      mat-table
      [dataSource]="resolved.data || []">
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox
            *ngIf="resolved.data"
            [checked]="usersSelection.hasValue() && isAllSelected(resolved.data)"
            [indeterminate]="usersSelection.hasValue() && !isAllSelected(resolved.data)"
            (change)="$event ? toggleAllRows(resolved.data) : null">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox
            [checked]="usersSelection.isSelected(row)"
            (click)="$event.stopPropagation()"
            (change)="$event ? usersSelection.toggle(row) : null">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell>FirstName</th>
        <td *matCellDef="let row" mat-cell>{{ row.firstName || '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th *matHeaderCellDef mat-header-cell>LastName</th>
        <td *matCellDef="let row" mat-cell>{{ row.lastName || '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th *matHeaderCellDef mat-header-cell>
          Email
        </th>
        <td *matCellDef="let row" mat-cell>{{ row.email || '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th *matHeaderCellDef mat-header-cell>
          Phone Number
        </th>
        <td *matCellDef="let row" mat-cell>{{ row.phoneNumber || '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th *matHeaderCellDef mat-header-cell>
          Roles
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-chip
            *ngFor="let role of row.roles"
            disabled >{{ role }}
          </mat-chip>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th *matHeaderCellDef mat-header-cell>
          State
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-slide-toggle
            [checked]="row.isEnabled"
            (change)="toggleUserStateHandler(row)">
        </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="show" stickyEnd>
        <th *matHeaderCellDef mat-header-cell>
        </th>
        <td *matCellDef="let row" mat-cell>
          <button
            mat-icon-button
            [routerLink]="['/user', row.id]">
            <mat-icon fontIcon="arrow_forward"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        *matHeaderRowDef="displayedColumns"
        mat-header-row>
      </tr>
      <tr
        *matRowDef="let row; columns: displayedColumns;"
        mat-row>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="resolved.data?.length"
    [pageSize]="250">
  </mat-paginator>
</div>
