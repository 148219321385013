import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, NoAuthGuard } from './core/guards';
import { GroupPageComponent } from './pages/group-page/group-page.component';
import { GroupsPageComponent } from './pages/groups-page/groups-page.component';
import { InnerRootPageComponent } from './pages/inner-root-page/inner-root-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    loadComponent: () => LoginPageComponent,
  },
  {
    path: 'logout',
    loadComponent: () => LogoutPageComponent,
  },
  {
    path: '',
    component: InnerRootPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        loadComponent: () => UsersPageComponent,
      },
      {
        path: 'user/:id',
        loadComponent: () => UserPageComponent,
      },
      {
        path: 'groups',
        loadComponent: () => GroupsPageComponent,
      },
      {
        path: 'group/:id',
        loadComponent: () => GroupPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true,
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
