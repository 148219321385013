import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AdminService, Group, UserItemAdmin } from 'ngx-atred-api-connectors';
import { firstValueFrom } from 'rxjs';

const displayGroupLabel = (group: Group) => group?.name || '';

@Component({
  selector: 'app-add-users-group-dialog',
  templateUrl: './add-users-group-dialog.component.html',
  styleUrls: ['./add-users-group-dialog.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    NgFor,
    NgIf,
    ReactiveFormsModule,
  ],
})
export class AddUsersGroupDialogComponent {
  private readonly adminApi = inject(AdminService);
  private readonly dialogRef = inject(MatDialogRef);
  readonly data: { users: UserItemAdmin[] } = inject(MAT_DIALOG_DATA);

  readonly groups$ = this.adminApi.v0AdminGroupsGet();

  readonly groupForm = inject(FormBuilder).nonNullable.group({
    group: [{} as Group, Validators.required],
  });

  readonly userErrors: Record<string, UserItemAdmin> = {};

  readonly displayGroupLabel = displayGroupLabel;

  async saveHandler() {
    if (this.groupForm.invalid) {
      this.groupForm.markAllAsTouched();
      return;
    }

    const groupId = this.groupForm.getRawValue().group.id;
    if (!groupId) {
      throw new Error('Cannot save without a group');
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const user of this.data.users) {
      if (!user.id) {
        return;
      }

      try {
        // eslint-disable-next-line no-await-in-loop
        await firstValueFrom(
          this.adminApi.v0AdminGroupsGroupIdParticipantsPost({ groupId, body: { userId: user.id } }),
        );
      } catch {
        // eslint-disable-next-line no-continue
        continue;
      }
    }

    this.dialogRef.close();
  }

  removeUser(user: UserItemAdmin) {
    if (!user.id) {
      return;
    }

    this.data.users = this.data.users.filter((u) => u.id !== user.id);
    delete this.userErrors[user.id];
  }
}
