<div class="flex justify-between">
  <h1>Groups</h1>

  <button
    mat-stroked-button
    (click)="createGroupHandler()">
    New group
    <mat-icon [fontIcon]="'add'"></mat-icon>
  </button>
</div>

<div
  *ngIf="{ data: groups$ | async } as resolved"
  class="!border border-gray-200">
  <div *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div>
    <table
      mat-table
      [dataSource]="resolved.data || []">

      <ng-container matColumnDef="name" sticky>
        <th *matHeaderCellDef mat-header-cell>Name</th>
        <td *matCellDef="let row" mat-cell>{{ row.name }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell>Description</th>
        <td *matCellDef="let row" mat-cell>{{ row.description }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th *matHeaderCellDef mat-header-cell>
          Created
        </th>
        <td *matCellDef="let row" mat-cell>{{ row.created_at | date }}</td>
      </ng-container>

      <ng-container matColumnDef="show" stickyEnd>
        <th *matHeaderCellDef mat-header-cell>
        </th>
        <td *matCellDef="let row" mat-cell>
          <button
            mat-icon-button
            [routerLink]="['/group', row.id]">
            <mat-icon fontIcon="arrow_forward"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        *matHeaderRowDef="displayedColumns"
        mat-header-row>
      </tr>
      <tr
        *matRowDef="let row; columns: displayedColumns;"
        mat-row>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="resolved.data?.length"
    [pageSize]="250">
  </mat-paginator>
</div>
