import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AdminService, AuthenticationService, UserItemAdmin } from 'ngx-atred-api-connectors';
import {
  BehaviorSubject, firstValueFrom,
} from 'rxjs';
import {
  filter, switchMap,
} from 'rxjs/operators';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    NgFor,
    NgIf,
    RouterModule,
  ],
})
export class UserPageComponent {
  private readonly userId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() set id(v: string | undefined) {
    this.userId$.next(v);
  }

  private readonly adminApi = inject(AdminService);
  private readonly authenticationApi = inject(AuthenticationService);
  private readonly snackBar = inject(MatSnackBar);

  readonly user$ = this.userId$.pipe(
    filter((userId): userId is string => !!userId),
    switchMap((userId) => this.adminApi.v0AdminUsersIdGet({ id: userId })),
  );

  isLoadingResults = false;

  async resetPasswordHandler(user: UserItemAdmin) {
    if (!user.email) {
      return;
    }

    await firstValueFrom(this.authenticationApi.v0AuthInitPasswordResetPut({
      body: { email: user.email },
    }));

    this.snackBar.open(
      'Reset password request successfully sent',
      undefined,
      { verticalPosition: 'bottom', horizontalPosition: 'center', duration: 4000 },
    );
  }
}
