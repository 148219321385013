<div
  class="inner-container"
  [class.is-mobile]="mobileQuery.matches" >
  <mat-toolbar class="inner-toolbar justify-between">
    <div class="flex items-center">
      <button
        mat-icon-button
        class="mr-4 flex items-center"
        (click)="nav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Lenovys ATRED Admin</span>
    </div>
    <div class="flex items-center">
      <button
        mat-icon-button
        (click)="logoutHandler()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container
    class="sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav
      #nav
      fixedTopGap="56"
      class="w-56"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches">
      <mat-nav-list>
        <a
          mat-list-item
          routerLinkActive="!bg-gray-100"
          routerLink="/users">
          Users
        </a>
        <a
          mat-list-item
          routerLinkActive="!bg-gray-100"
          routerLink="groups">
          Groups
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="p-4">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
