import { Injectable, inject } from '@angular/core';
import jwt_decode from 'jwt-decode';
import {
  AuthenticationService,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
} from 'ngx-atred-api-connectors';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

const getUniqueName = (accessToken?: string) => {
  const payload: { unique_name: string } | undefined = accessToken ? jwt_decode(accessToken) : undefined;
  return payload?.unique_name;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authApi = inject(AuthenticationService);

  readonly credentials$ = new BehaviorSubject<LoginResponse | undefined>(undefined);

  get accessToken() {
    return this.credentials$.value?.authToken;
  }

  get refreshToken() {
    return this.credentials$.value?.refreshToken;
  }

  get refreshTokenExpiresAt() {
    return this.credentials$.value?.refreshTokenExpiresAt;
  }

  async init() {
    let r: LoginResponse | undefined;
    try {
      const token = localStorage.getItem('token');
      if (token) {
        r = JSON.parse(token);
      }
      this.credentials$.next(r);
      const { authToken } = await this.refresh(r?.refreshToken, r?.authToken);

      if (!r) {
        return;
      }

      const newCredentials = authToken
        ? { authToken, refreshToken: r?.refreshToken, refreshTokenExpiresAt: r?.refreshTokenExpiresAt }
        : r;
      this.setToken(newCredentials);
    } catch (e) {
      // Silence error
    }
  }

  async signup(body: RegisterRequest) {
    const r = await firstValueFrom(this.authApi.v0AuthRegisterPost({ body }));
    this.setToken(r);
  }

  async login(body: LoginRequest) {
    const r = await firstValueFrom(this.authApi.v0AuthLoginPost({ body }));
    this.setToken(r);
  }

  async logout() {
    try {
      const { refreshToken } = this;
      const username = getUniqueName(this.accessToken);
      if (refreshToken && username) {
        await firstValueFrom(this.authApi.v0AuthRefreshTokensInvalidatePut({ body: { refreshToken, username } }));
      }
    } finally {
      localStorage.removeItem('token');
      this.credentials$.next(undefined);
    }
  }

  async refresh(refreshToken?: string, accessToken?: string) {
    if (!refreshToken || !accessToken) {
      throw new Error('Cannot refresh token without a refresh token');
    }

    const username = getUniqueName(accessToken);

    if (!username || !this.refreshTokenExpiresAt) {
      throw new Error('Cannot refresh token without a username');
    }

    const newToken = await firstValueFrom(this.authApi.v0AuthRefreshPost({
      body: { refreshToken, username },
    }));

    const newCredentials = {
      authToken: newToken.authToken,
      refreshToken,
      refreshTokenExpiresAt: this.refreshTokenExpiresAt,
    };

    this.setToken(newCredentials);
    return newToken;
  }

  setToken(token?: LoginResponse) {
    localStorage.setItem('token', JSON.stringify(token));
    this.credentials$.next(token);
  }
}
